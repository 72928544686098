'use client';
/**
 * The default CTA button, used to render buttons from Portable Text. Composes functionality from ButtonBase
 * @param props see ButtonBase props
 * @example <Button props={props} />
 */

import { capitalize } from 'lodash';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';

import ButtonIcon from '~/components/atoms/Buttons/Ctas/Button/ButtonIcon/ButtonIcon';
import { cn } from '~/utils';

import ButtonBase from '../ButtonBase';
import styles from './Button.module.css';
import { ButtonProps, ForwardedButtonRef } from './Button.types';
import PlayWithLabelButton from './PlayWithLabelButton/PlayWithLabelButton';

/**
 * CTA component
 * @param buttonVariant The variant of the cta, selected in the CMS, defaults to "pill"
 * @param buttonColorScheme The cta's color scheme, only applies to pill variant, selected in the CMS
 * @param to The cta's link, determines whether component will render as <a> or <button>, set in the CMS
 * @param disabled If the cta is disabled
 * @param className
 */
const Button = (props: ButtonProps, ref: ForwardedRef<ForwardedButtonRef>) => {
  const {
    children,
    buttonVariant: cmsButtonVariant,
    buttonColorScheme = 'white',
    className,
    to,
    disabled,
    iconType,
    iconPosition = 'right',
    buttonCustomIcon,
  } = props;

  const $icon = useRef(null);
  const $button = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      $icon,
      $button,
    }),
    [],
  );

  let buttonVariant = cmsButtonVariant;
  if (!buttonVariant && to?.type === 'phoneNumber') {
    // phoneNumber link don't have a buttonVariant, and should always be a primary
    buttonVariant = 'primary';
  }

  const primaryWithIcon =
    buttonVariant !== 'textLink' &&
    (buttonCustomIcon ||
      to?.type === 'download' ||
      to?.type === 'phoneNumber' ||
      to?.type === 'videoModal');

  const renderClasses = cn(
    buttonVariant === 'primary' && buttonColorScheme
      ? styles[buttonColorScheme]
      : styles.textLink,
    (iconType === 'rightArrow' || buttonVariant === 'textLink') &&
      styles.hasRightArrow,
    primaryWithIcon && styles.primaryWithIcon,
    iconPosition &&
      styles[
        `has${
          iconPosition === 'iconOnly'
            ? 'IconOnly'
            : `Icon${capitalize(iconPosition)}`
        }`
      ],
    to?.type === 'phoneNumber' && styles.phoneNumber,
    disabled && styles.disabled,
    className,
  );
  //  video modal
  return to?.type === 'videoModal' || buttonVariant === 'playWithLabel' ? (
    <PlayWithLabelButton
      {...props}
      buttonVariant={buttonVariant}
      to={to && to?.type === 'videoModal' ? to : undefined}
      buttonRef={$button}
      className={cn(
        buttonVariant === 'primary' && buttonColorScheme
          ? styles[buttonColorScheme]
          : styles.textLink,
        buttonVariant === 'primary' && styles.primaryWithIcon,
        buttonVariant === 'primary' && styles.hasIconLeft,
      )}
    >
      {children}
    </PlayWithLabelButton>
  ) : (
    <ButtonBase {...props} ref={$button} className={renderClasses}>
      {buttonVariant === 'primary' && iconPosition !== 'right' && (
        <ButtonIcon
          to={to}
          buttonVariant={buttonVariant}
          ref={$icon}
          buttonCustomIcon={buttonCustomIcon}
          hasBorder={iconPosition !== 'iconOnly'}
          isLarge={iconPosition === 'iconOnly'}
        />
      )}
      <span
        className={cn(
          styles.buttonText,
          iconPosition === 'iconOnly' && 'visuallyHidden',
        )}
      >
        {children}
      </span>
      {buttonVariant === 'primary' && iconPosition === 'right' && (
        <ButtonIcon
          to={to}
          buttonVariant={buttonVariant}
          ref={$icon}
          buttonCustomIcon={buttonCustomIcon}
          hasBorder
        />
      )}
      {((buttonVariant === 'primary' && iconType === 'rightArrow') ||
        buttonVariant === 'textLink') && (
        <ButtonIcon to={to} buttonVariant={'textLink'} ref={$icon} />
      )}
    </ButtonBase>
  );
};

export default forwardRef(Button);
