'use client';
/**
 * Renders the icon for the default CTAs. Renders a download icon for download links, and arrow icon for internal and external links.
 * @param buttonVariant The variant of the parent cta, determines whether icon should render. Icon will only be paired with "textWithIcon" variant.
 * @param to The parent CTA's link
 * @example <ButtonIcon buttonVariant={buttonVariant} to-{to} />
 */
import { ForwardedRef, forwardRef } from 'react';

import SvgDownload from '~/assets/svg/download.svg';
import SvgPhone from '~/assets/svg/phone.svg';
import SvgPlayIcon from '~/assets/svg/play-icon.svg';
import Graphic from '~/components/atoms/Graphic/Graphic';
import { cn } from '~/utils';

import AnimatedSvgArrow from '../../../AnimatedSvgArrow/AnimatedSvgArrow';
import styles from './ButtonIcon.module.css';
import { ButtonIconProps } from './ButtonIcon.types';

const ButtonIcon = (
  {
    className,
    buttonVariant,
    to,
    buttonCustomIcon,
    hasBorder,
    isLarge,
  }: ButtonIconProps,
  ref: ForwardedRef<HTMLElement>,
) => {
  if (buttonVariant === 'textLink') {
    switch (to?.type) {
      case 'phoneNumber':
        return (
          <span className={cn(styles.textLinkContainer, className)}>
            <span className={styles.iconWrapper}>
              <SvgPhone className={styles.icon} ref={ref} />
            </span>
          </span>
        );

      case 'download':
        return (
          <span className={cn(styles.textLinkContainer, className)} ref={ref}>
            <span className={styles.iconWrapper}>
              <SvgDownload className={styles.icon} />
            </span>
          </span>
        );
      default:
        return (
          <span className={cn(styles.textLinkContainer, className)} ref={ref}>
            <AnimatedSvgArrow />
          </span>
        );
    }
  } else if (buttonVariant === 'primary' && buttonCustomIcon) {
    return (
      <span
        className={cn(
          styles.iconContainer,
          hasBorder && styles.withBorder,
          isLarge && styles.isLarge,
          className,
        )}
        ref={ref}
      >
        <span className={styles.iconWrapper}>
          <Graphic {...buttonCustomIcon} className={styles.icon} />
        </span>
      </span>
    );
  } else {
    switch (to?.type) {
      case 'phoneNumber':
        return (
          <span
            className={cn(
              styles.iconContainer,
              hasBorder && styles.withBorder,
              className,
            )}
          >
            <span className={styles.iconWrapper}>
              <SvgPhone className={styles.icon} ref={ref} />
            </span>
          </span>
        );

      case 'download':
        return (
          <span
            className={cn(
              styles.iconContainer,
              styles.downloadIconContainer,
              hasBorder && styles.withBorder,
              className,
            )}
            ref={ref}
          >
            <span className={styles.iconWrapper}>
              <SvgDownload className={styles.icon} />
            </span>
          </span>
        );

      case 'videoModal':
        return (
          <span
            className={cn(
              styles.iconContainer,
              styles.playIconContainer,
              hasBorder && styles.withBorder,
              className,
            )}
            ref={ref}
          >
            <span className={styles.iconWrapper}>
              <SvgPlayIcon className={cn(styles.icon, styles.playIcon)} />
            </span>
          </span>
        );
    }
  }
};

export default forwardRef(ButtonIcon);
