import { RefObject, useCallback, useRef, useState } from 'react';

import ButtonIcon from '~/components/atoms/Buttons/Ctas/Button/ButtonIcon/ButtonIcon';
import { CMSLinkProps } from '~/components/atoms/Link/Link.types';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import ModalVideo from '~/components/molecules/Modal/ModalVideo/ModalVideo';
import { cn } from '~/utils';

import ButtonPlay from '../../../UI/ButtonPlay/ButtonPlay';
import ButtonBase from '../../ButtonBase';
import { ButtonProps } from '../Button.types';
import styles from './PlayWithLabelButton.module.css';

const PlayWithLabelButton = (
  props: ButtonProps & {
    buttonRef: RefObject<HTMLElement>;
    to?: CMSLinkProps & { type: 'videoModal' };
  },
) => {
  const { to, buttonVariant, className, children, buttonRef, onClick } = props;

  // Video modal logic
  const $modalVideo = useRef<VideoRef>(null);
  const onClickPlay = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      } else {
        setShowVideoModal(true);
      }
    },
    [onClick],
  );

  const [showVideoModal, setShowVideoModal] = useState(false);

  const onModalLeave = () => {
    $modalVideo.current?.muteSmoothly();

    if (buttonRef.current) {
      // return focus to play button
      buttonRef.current.focus();
    }
  };

  return (
    <ButtonBase
      {...props}
      onClick={onClickPlay}
      ref={buttonRef}
      className={cn(className, styles.playWithLabelButton)}
    >
      {to && (
        <ModalVideo
          video={to.video}
          modalTriggeredByClick={showVideoModal}
          onModalLeave={onModalLeave}
          onModalClose={() => setShowVideoModal(false)}
        />
      )}
      {buttonVariant === 'primary' && (
        <ButtonIcon to={to} buttonVariant={buttonVariant} hasBorder />
      )}
      {buttonVariant !== 'primary' && (
        <ButtonPlay
          className={styles.buttonPlay}
          tag="span"
          buttonColorScheme={'white'}
        />
      )}
      {children}
    </ButtonBase>
  );
};

export default PlayWithLabelButton;
