'use client';

import { mergeRefs } from '@react-aria/utils';
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';

import ButtonPlay from '~/components/atoms/Buttons/UI/ButtonPlay/ButtonPlay';
import Video from '~/components/atoms/Video/Video';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import useUIStore from '~/state/ui';
import { cn } from '~/utils';

import styles from './PlayableVideo.module.css';
import { PlayableVideoProps } from './PlayableVideo.types';

const PlayableVideo = (
  props: PlayableVideoProps,
  ref: ForwardedRef<VideoRef>,
) => {
  const {
    thumbnail,
    src,
    autoplayPreview,
    isLooping,
    forceIsInView,
    forceAutoplay = false,
    forceVideoStart = false,
    displayPreview = false,
    loopLength = 5,
    playButtonClassName,
    className,
  } = props;
  const [videoStarted, setVideoStarted] = useState(false);
  const previewType = thumbnail && !autoplayPreview ? 'image' : 'video';
  const internalVideoRef = useRef<VideoRef>(null);

  const currentFullscreenVideoSrc = useUIStore(
    (state) => state.currentFullscreenVideoSrc,
  );

  const isPreview =
    displayPreview && !videoStarted && currentFullscreenVideoSrc !== src;

  useEffect(() => {
    if (videoStarted) {
      // because we're autoplaying a preview on mobile, the video needs to be set to play inline and native behavior won't open full screen on play. therefore, we need to manually trigger fullscreen when the video starts
      if (
        navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad)/) &&
        autoplayPreview
      ) {
        internalVideoRef.current?.toggleFullscreen();
      }

      internalVideoRef.current?.unmuteSmoothly(0);

      internalVideoRef.current?.restart();
    }
  }, [videoStarted, autoplayPreview]);

  const startVideo = () => {
    if (!videoStarted) setVideoStarted(true);
  };

  useEffect(() => {
    if (forceVideoStart && !videoStarted) setVideoStarted(true);
  }, [forceVideoStart, videoStarted, setVideoStarted]);

  return (
    <>
      {isPreview && (
        <ButtonPlay
          onClick={startVideo}
          className={cn(styles.playButton, playButtonClassName)}
        />
      )}

      <Video
        {...props}
        willAutoplay={isPreview && autoplayPreview ? true : forceAutoplay}
        isLooping={isLooping}
        isMuted={isPreview && autoplayPreview ? true : false}
        loopLength={isPreview && autoplayPreview ? loopLength : undefined}
        controls={isPreview ? false : true}
        playsInline={isPreview && autoplayPreview ? true : false}
        forceIsInView={forceIsInView || forceAutoplay ? true : false}
        ref={mergeRefs(ref, internalVideoRef)}
        displayStaticThumbnail={isPreview && previewType === 'image'}
        thumbnailClassName={styles.thumbnail}
        className={className}
      />
    </>
  );
};

export default forwardRef(PlayableVideo);
