import { MouseEvent, ReactNode, RefObject } from 'react';

import { CMSVideoProps } from '~/components/atoms/Video/Video.types';

export type LinkProps = {
  className?: string;
  to?: CMSLinkProps;
  children: ReactNode;
  ref: RefObject<HTMLElement>;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: (e?: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  tabIndex?: number;
  role?: string;
};

export type CMSLinkProps = {
  _key?: string;
  label?: string;
  url: string;
  hash?: string | null;
} & (
  | {
      _type: 'videoModal';
      type: 'videoModal';
      video: CMSVideoProps;
    }
  | {
      _type?: 'link' | 'internalLink' | 'download' | 'mailTo';
      type: LinkTypeOptions;
      video?: never;
    }
);

export type LinkTypeOptions =
  | 'internal'
  | 'external'
  | 'download'
  | 'phoneNumber';

// links to app.frame.io and accounts.frame.io should open in the same window
export const SAME_TAB_EXTERNAL_DOMAINS = ['app.frame.io', 'accounts.frame.io'];
