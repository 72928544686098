'use client';
import { breakpoints } from '@frameio-bs/tokens';
import { mergeRefs } from '@react-aria/utils';
import debounce from 'lodash/debounce';
import {
  cloneElement,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { cn } from '~/utils';
import SharedIntersectionObserver from '~/utils/observers/SharedIntersectionObserver';
import { tickerAddOnce } from '~/utils/ticker';

import parentStyles from '../Image.module.css';
import { MAX_DPR, QUALITY_CUSHION } from '../Image.types';
import { getAspectRatio, getContainedImageURL, urlFor } from '../utils';
import styles from './Dynamic.module.css';
import { DEFAULT_OBSERVER_OPTIONS, DynamicProps } from './Dynamic.types';

/**
 * This component will automatically add the right `src` attribute to our `<img>`
 * based on its dimensions, measured either by a intersection observer, or by a
 * `getClientBoundingRect` if the loading is overriden by `isDisplayed`
 * @returns The figure element with the cloned `<img>` tag in it
 */
const Dynamic = (
  {
    children,
    source,
    quality,
    isDisplayed: forcedDisplay,
    className,
    contain,
    autoResize,
    fixedAspectRatio,
    animated = true,
    blur,
    dpr,
  }: DynamicProps,
  ref: ForwardedRef<HTMLElement>,
) => {
  const [img, setImg] = useState<ReactElement<HTMLImageElement> | null>(null);
  const [isDisplayed, setIsDisplayed] = useState(!!forcedDisplay);

  const isObservedForResize = useRef(false);

  const $wrapper = useRef<HTMLDivElement>(null);

  const smallFallback = {
    min: breakpoints.find((breakpoint) => breakpoint.name === 'sm')?.min || 0,
    width: breakpoints.find((breakpoint) => breakpoint.name === 'md')?.min || 0,
  };
  const largeFallback = {
    min: breakpoints.find((breakpoint) => breakpoint.name === 'md')?.min || 0,
    width: breakpoints.find((breakpoint) => breakpoint.name === 'lg')?.min || 0,
  };

  const updateImg = useCallback(
    (rect: DOMRect) => {
      // If the image is small, the round up is every 50px, 100px otherwise
      const increment = rect.width < 300 ? 50 : 100;

      const width = Math.ceil(rect.width / increment) * increment;
      let height = Math.round(width / (rect.width / rect.height));

      if (typeof fixedAspectRatio === 'undefined') {
        height = Math.ceil(height / increment) * increment;
      }

      let src = '';
      if (width && height) {
        let srcObject = urlFor(source)
          .width(width)
          .height(height)
          .quality(quality)
          .dpr(dpr || Math.min(devicePixelRatio, MAX_DPR) * QUALITY_CUSHION)
          .auto('format');

        if (contain) {
          srcObject = getContainedImageURL(source, srcObject);
        }

        if (blur) {
          srcObject = srcObject.blur(blur);
        }

        src = srcObject.url();
      }
      if (src !== img?.props.src) {
        setImg(
          cloneElement(children, {
            src,
            className: cn(
              children.props.className,
              styles.image,
              animated && styles.animated,
            ),
          }),
        );
      }
    },
    [
      animated,
      blur,
      children,
      contain,
      dpr,
      fixedAspectRatio,
      img,
      quality,
      source,
    ],
  );

  useEffect(() => {
    setIsDisplayed(!!forcedDisplay);
  }, [forcedDisplay]);

  useEffect(() => {
    if (isDisplayed && img === null) {
      tickerAddOnce(() => {
        const rect = $wrapper.current?.getBoundingClientRect();
        if (rect) {
          updateImg(rect);
        }
      }, true);
    }
  }, [isDisplayed, updateImg, img]);

  useEffect(() => {
    const isExternallyControlled = typeof forcedDisplay !== 'undefined';
    if (!isExternallyControlled && $wrapper.current) {
      const $element = $wrapper.current;
      // Let's setup the intersection observer
      SharedIntersectionObserver.subscribe(
        $element,
        (rect) => {
          if (rect) {
            updateImg(rect);
            if ($element) {
              SharedIntersectionObserver.unsubscribe(
                $element,
                DEFAULT_OBSERVER_OPTIONS,
              );
            }
            setIsDisplayed(true);
          }
        },
        DEFAULT_OBSERVER_OPTIONS,
      );

      return () => {
        if ($element) {
          SharedIntersectionObserver.unsubscribe(
            $element,
            DEFAULT_OBSERVER_OPTIONS,
          );
        }
      };
    }
  }, [forcedDisplay, updateImg]);

  useEffect(() => {
    if (
      $wrapper.current &&
      isObservedForResize.current === false &&
      autoResize !== false
    ) {
      isObservedForResize.current = true;
      const observer = new ResizeObserver(
        debounce((entries) => {
          for (const entry of entries) {
            if (isDisplayed) {
              // entry contentRect is not reliable as it doesn't take into account transforms
              tickerAddOnce(() => {
                updateImg(entry.target.getBoundingClientRect());
              }, true);
            }
          }
        }, 100),
      );

      observer.observe($wrapper.current);

      return () => {
        isObservedForResize.current = false;
        observer.disconnect();
      };
    }
  }, [autoResize, isDisplayed, updateImg]);

  const customAttributes: HTMLAttributes<HTMLDivElement> = {};

  const aspectRatio = getAspectRatio(source);

  customAttributes.style = {
    '--default-image-aspect-ratio': aspectRatio,
  } as CSSProperties;

  return (
    <figure
      className={cn(className, parentStyles.wrapper)}
      ref={mergeRefs(ref, $wrapper)}
      {...customAttributes}
    >
      {img}
      <div aria-hidden={img ? true : false}>
        <noscript>
          <picture>
            <source
              media={`(min-width: ${smallFallback.min}px)`}
              src={urlFor(source)
                .quality(quality)
                .auto('format')
                // Arbitrary low value as a fallback
                .width(smallFallback.width)
                .blur(blur || 0)
                .url()}
            />
            <source
              media={`(min-width: ${largeFallback.min}px)`}
              src={urlFor(source)
                .quality(quality)
                .auto('format')
                // Arbitrary low value as a fallback
                .width(largeFallback.width)
                .blur(blur || 0)
                .url()}
            />
            {cloneElement(children, {
              // This image is used by bots almost exclusively - hence the very low quality
              src: urlFor(source)
                .quality(50)
                .auto('format')
                .width(100)
                .blur(blur || 0)
                .url(),
              loading: 'lazy',
            })}
          </picture>
        </noscript>
      </div>
    </figure>
  );
};

export default forwardRef(Dynamic);
