'use client';

import noop from 'lodash/noop';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';

import { VideoRef } from '~/components/atoms/Video/Video.types';
import Modal from '~/components/molecules/Modal/Modal';
import PlayableVideo from '~/components/molecules/PlayableVideo/PlayableVideo';
import {
  CMSVideoPlaylistVideo,
  CMSVideoPlaylistVideoNoDetail,
  VideoList,
  VideoListNoDetail,
} from '~/components/organisms/modules/VideoPlaylist/VideoPlaylist.types';

import { ModalVideoProps } from './ModalVideo.types';
import ThumbnailList from './ThumbnailsList/ThumbnailsList';

/**
 * A modal that contains a video or a playlist of videos.
 * @param video A single data object containing the video properties
 * @param videoList An array of video data objects
 * @param modalTriggeredByClick If the modal is triggered by clicking on an external element (versus deeplink)
 * @param selectedItemIndex The index of the videoList item selected when triggered by click
 * @param onVideoEnded Callback for when the current video has ended
 * @param onModalLeave Callback for when the modal starts to close
 * @param onModalClose Callback for when the modal has finished closing
 * @example <ModalVideo video={video} modalTriggeredByClick={showVideoModal} onModalLeave={onModalLeave} onModalClose={() => setShowVideoModal(false)} />
 */
const ModalVideo = ({
  video,
  videosList,
  modalTriggeredByClick,
  selectedItemIndex,
  onVideoEnded = noop,
  onModalLeave = noop,
  onModalClose = noop,
}: ModalVideoProps) => {
  const pathname = usePathname();

  const $modalVideo = useRef<VideoRef>(null);

  const [showVideoModal, setShowVideoModal] = useState(false);

  const playlist = useRef<VideoList | VideoListNoDetail>();

  const [currentItemIndex, setCurrentItemIndex] = useState<number>();

  const [currentVideo, setCurrentVideo] = useState<
    CMSVideoPlaylistVideo | CMSVideoPlaylistVideoNoDetail
  >();

  useEffect(() => {
    if (video) {
      playlist.current = [{ video }];
    } else {
      playlist.current = videosList;
    }

    if (videosList && currentItemIndex) {
      // if it's a playlist, update to the current item, or clear it to be undefined
      setCurrentVideo(playlist.current[currentItemIndex]);
    } else {
      // if it's a single item, the current video will be the first and (only) item
      setCurrentVideo(playlist.current[0]);
    }
  }, [video, videosList, currentItemIndex]);

  useEffect(() => {
    if (
      selectedItemIndex === null ||
      typeof selectedItemIndex === 'undefined'
    ) {
      return;
    }

    setCurrentItemIndex(selectedItemIndex);

    if (playlist.current) {
      setCurrentVideo(playlist.current[selectedItemIndex]);
    }
  }, [selectedItemIndex]);

  useEffect(() => {
    if (!showVideoModal) {
      setShowVideoModal(modalTriggeredByClick);
      if (modalTriggeredByClick) {
        // will autoplay modal video on ios
        $modalVideo.current?.restart();

        // the video modal is reopening after closing/muting smoothly, the volume needs to be reset to 1
        $modalVideo.current?.unmuteSmoothly(0);
      }
    }
  }, [modalTriggeredByClick, currentVideo, showVideoModal]);

  const selectItem = useCallback(
    (item: CMSVideoPlaylistVideo) => {
      if (videosList) {
        setCurrentItemIndex(
          videosList.findIndex(
            (video: CMSVideoPlaylistVideo) => video === item,
          ),
        );
      }
      setCurrentVideo(item);
    },
    [videosList],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const query = searchParams?.get('videoId');

    playlist.current?.forEach((item, i) => {
      if (item.video?.videoId === query) {
        if (playlist.current) {
          selectItem(playlist.current[i] as CMSVideoPlaylistVideo);
        }
        setShowVideoModal(true);
      }
    });
  }, [pathname, selectItem]);

  const handleVideoEnded = () => {
    if (playlist.current && typeof currentItemIndex !== 'undefined') {
      const newIndex = Math.min(
        currentItemIndex + 1,
        playlist.current.length - 1,
      );
      setCurrentItemIndex(newIndex);
      setCurrentVideo(playlist.current[newIndex]);
    }

    onVideoEnded();
  };

  const handleLeave = () => {
    $modalVideo.current?.muteSmoothly();

    onModalLeave();
  };

  const handleClose = () => {
    $modalVideo.current?.pause();
    setShowVideoModal(false);
    onModalClose();
  };

  const renderFooter = () => {
    if (!videosList || typeof currentItemIndex === 'undefined') return null;

    return (
      <ThumbnailList
        currentIndex={currentItemIndex}
        videosList={videosList}
        onSelectVideo={selectItem}
      />
    );
  };

  // detect if details are going to be present

  const detailsPlaylistVideo = currentVideo as CMSVideoPlaylistVideo;
  const isNoDetail = typeof detailsPlaylistVideo?.title === 'undefined';
  let details;

  if (!isNoDetail) {
    details = {
      title: detailsPlaylistVideo.title,
      subtitle: `${detailsPlaylistVideo.subtitle}`,
    };
  }
  return (
    <Modal
      onClose={handleClose}
      onLeave={handleLeave}
      footer={renderFooter()}
      showModal={showVideoModal}
    >
      {currentVideo && (
        <PlayableVideo
          ref={$modalVideo}
          {...currentVideo.video}
          src={currentVideo.video.url}
          forceAutoplay={showVideoModal}
          disablePictureInPicture
          onEnded={handleVideoEnded}
          details={details}
          shouldFocusControls={true}
        />
      )}
    </Modal>
  );
};

export default ModalVideo;
