'use client';
import { mergeRefs } from '@react-aria/utils';
import { ForwardedRef, forwardRef, useCallback, useRef, useState } from 'react';

import { VideoRef } from '~/components/atoms/Video/Video.types';
import ModalVideo from '~/components/molecules/Modal/ModalVideo/ModalVideo';

import { VideoModalLinkProps } from './VideoModalLink.types';

// Displayed as button
const VideoModalLink = (
  props: VideoModalLinkProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const { to, children, onClick } = props;
  // Video modal logic
  const $modalVideo = useRef<VideoRef>(null);
  const $button = useRef<HTMLButtonElement>(null);
  const onClickPlay = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      } else {
        setShowVideoModal(true);
      }
    },
    [onClick],
  );

  const [showVideoModal, setShowVideoModal] = useState(false);

  const onModalLeave = () => {
    $modalVideo.current?.muteSmoothly();

    if ($button.current) {
      // return focus to play button
      $button.current.focus();
    }
  };

  return (
    <button {...props} onClick={onClickPlay} ref={mergeRefs(ref, $button)}>
      {to && (
        <ModalVideo
          video={to.video}
          modalTriggeredByClick={showVideoModal}
          onModalLeave={onModalLeave}
          onModalClose={() => setShowVideoModal(false)}
        />
      )}
      {children}
    </button>
  );
};

export default forwardRef<HTMLButtonElement, VideoModalLinkProps>(
  VideoModalLink,
);
