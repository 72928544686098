import { RefObject } from 'react';
import { OnProgressProps } from 'react-player/base';
import ReactPlayer from 'react-player/file';

import { CMSImage } from '~/components/atoms/Image/Image.types';

export type VideoDetails = {
  title?: string;
  subtitle?: string;
};

export type CMSVideoProps = {
  url: string;
  mp4Url: string;
  name: string;
  isLooping?: boolean;
  autoplayPreview?: boolean;
  aspectRatio?: number;
  mediaType?: 'video';
  thumbnail?: CMSImage;
  glowColor?: string;
  videoId?: string;
};

export type VideoProps = Omit<CMSVideoProps, 'url' | 'mp4Url' | 'name'> & {
  src: string;
  className?: string;
  thumbnailClassName?: string;
  isImageSequence?: boolean;
  disablePictureInPicture?: boolean;
  controls?: boolean;
  contain?: boolean;
  /**
   * Forces autoplaying videos to be in view on load instead of after scrolling, like when the video is the hero component for the page. used for bg media videos and autoplaying previews on the playable video
   **/
  forceIsInView?: boolean;
  playsInline?: boolean;
  willAutoplay?: boolean;
  isMuted?: boolean;
  details?: VideoDetails;
  loopLength?: number;
  shouldFocusControls?: boolean;
  displayStaticThumbnail?: boolean;
  playEndlessly?: boolean;
  forceUseMp4?: boolean;
  onClick?: () => void;
  onReady?: (e: HTMLImageElement) => void;
  onVideoReady?: (e: HTMLVideoElement) => void;
  onEnded?: () => void;
};

export type VideoRef = {
  $wrapper: RefObject<HTMLDivElement | null>;
  $player: HTMLVideoElement | null;
  play: () => void;
  pause: () => void;
  restart: (autoplay?: boolean) => void;
  seekTo: (amount: number) => void;
  setVolume: (volume: number) => void;
  muteSmoothly: (duration?: number) => void;
  unmuteSmoothly: (duration?: number) => void;
  forceMute: () => void;
  focusControls: () => void;
  toggleFullscreen: () => void;
};

export type VideoStateSubscriberCallbacks = {
  id: string;
  onProgress?: (progress: Partial<OnProgressProps>) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onMute?: () => void;
  onUnmute?: () => void;
  seekTo?: (amount: number) => void;
  play?: () => void;
  pause?: () => void;
  restart?: (autoplay: boolean) => void;
  mute?: () => void;
  unmute?: () => void;
  forceMute?: () => void;
  forceUnmute?: () => void;
  toggleMute?: () => void;
  toggleFullscreen?: () => void;
};

export type VideoStateProps = {
  $playerRef: RefObject<ReactPlayer>;
  $wrapper: RefObject<HTMLDivElement | null>;
  isMuted: boolean;
  subscribers: VideoStateSubscriberCallbacks[];
  subscribe: (callbacks: VideoStateSubscriberCallbacks) => () => void;
} & Omit<VideoStateSubscriberCallbacks, 'id'>;

// create interface that works with fullscreen api
export interface DocumentWithFullscreen extends Document {
  mozFullScreenElement?: Element;
  webkitFullscreenElement?: Element;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

export interface ElementWithFullScreen extends Element {
  mozRequestFullscreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

export const SESSION_STORAGE_MUTE = 'frame-video-mute';
